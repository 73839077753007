import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTable, usePagination } from 'react-table';

export function Table({ columns, data }) {

  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 9 } }, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                      style={{
                        width: column.width !== 150 ? column.width : undefined,
                      }}>
                      {
                        column.render('Header')}
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody style={{ maxHeight: '200px' }} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination"
        style={{ display: "flex", flexDirection: "row", marginTop: "5px" }}>
          <div style={{flexGrow:1}}></div>
        <div>
          <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <Tooltip title="première page">
              <FirstPage />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <Tooltip title="page précédente">
              <ChevronLeft />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
            <Tooltip title="page suivante">
              <ChevronRight />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <Tooltip title="dernière page">
              <LastPage />
            </Tooltip>
          </IconButton>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
      </div>
    </>
  );
}