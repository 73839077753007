
import { Table } from "components/common/table";
import React from "react";
import { GetEmployeesByCountryAndBu } from "services/employeeApiCaller";
import { employeeTableColumns } from "./employeeTableColumnDefinition";

export function EmployeeTable({ item }) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [employees, setEmployees] = React.useState([]);

    React.useEffect(() => {
        loadEmployees();
    }, [item]);


    function loadEmployees() {
        setIsLoading(true);
        if (item) {
            setIsLoading(true);
            GetEmployeesByCountryAndBu(item, (data) => {
                setEmployees(data);
                setIsLoading(false);
            });
        }
    }

    if (isLoading)
        return <div>Chargement...</div>;
    else
        return (
            <div
                style={{
                    width: '100%',
                    height: '100px',
                }}>
                <Table columns={employeeTableColumns} data={employees} />
            </div>
        );
}