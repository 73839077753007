import { Popover, TextField } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";

export function MigrateSjPopup({ handleClose, handleMigrate }) {

    const [structure, setStructure] = React.useState({
        stcCountryId: "",
        stcCountryLabel: "",
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}>

            <div
                style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <TextField
                    required
                    style={{
                        marginRight: '5px',
                    }}
                    label="Nouveau Pays Id"
                    value={structure.stcCountryId}
                    onChange={(e) => setStructure({...structure, stcCountryId : e.target.value})}
                />
                <TextField
                    label="Nouveau Pays Label"
                    value={structure.stcCountryLabel}
                    onChange={(e) => setStructure({...structure, stcCountryLabel : e.target.value})}
                />
            </div>
            <Button onClick={() => handleMigrate(structure)}>Migrer vers un pays</Button>
        </div >
    );
}