import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import { AppBar, Badge, Box, CssBaseline, IconButton, Menu, MenuItem, Popover, Toolbar, Typography } from "@mui/material";
import { config } from "config";
import { DehazeRounded } from "@mui/icons-material";


const isActive = ({ isCurrent }: any) => {
    return isCurrent
        ? { className: "active nav-link" }
        : { className: "nav-link" };
};
// `getProps` Calls up to you to get props for the underlying anchor element.
// Useful for styling the anchor as active.
const ExactNavLink = (props: any) => <Link getProps={isActive} {...props} />;

function NavBar({ userName }: any) {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    var appEnv = (
        <></>
    );
    if (config.REACT_APP_ENV !== "prod") {
        appEnv = (
            <>
                &nbsp;({config.REACT_APP_ENV})
            </>
        );
    }

    return (
        <Box sx={{backgroundColor:"#FFFFFA"}}>
            <Toolbar>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    align="center"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                    Gestion des structures Colas
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography>
                    {userName}
                </Typography>
            </Toolbar>
        </Box>

    );
}

const mapStateToProps = (state: any) => {
    return {
        userName: state.authentReducer.name,
    };
};

export default connect(mapStateToProps)(NavBar);
