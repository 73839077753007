import { Close, DriveFileMove, Height, HideSource, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Popover, IconButton, Tooltip } from "@mui/material";
import { WithStyles } from "@mui/styles";
import { TabPanel } from "components/common/tabPanel";
import { EmployeeTable } from "components/employee/employeeTable";
import { SjTable } from "components/sj/sjTable";
import React from "react";
import { GetDetailsByCountryAndBu } from "services/structureApiCaller";
import { DirProperty } from "./dirProperty";
import { MigratePopup } from "./migratePopUp";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = {
    dialogPaper: {
        minHeight: '500vh',
        maxHeight: '500vh',
    },
};


export function DialogCountry({ isOpen, item, itemId, handleHide, handleMigrate, handleClose }) {

    const [isLoading, setIsLoading] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState({});
    const [sjs, setSjs] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [ancholELMigratePopup, setAncholELMigratePopup] = React.useState(null);
    const open = Boolean(ancholELMigratePopup);

    React.useEffect(() => {
        if (item != null) {
            GetDetailsByCountryAndBu(item,
                (data) => {
                    setDetail(data);
                },
                () => {
                    setDetail({});
                })
        };
    }, [item]);

    function handleMigratePpopup(e) {
        setAncholELMigratePopup(e.currentTarget);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function hideButtonLabel() {
        if (detail != null && detail.isIgnored) {
            return "Afficher";
        }
        else {
            return "Masquer";
        }
    }

    function HideButtonIcon() {
        if (detail != null && detail.isIgnored) {
            return <Visibility />;
        }
        else {
            return <VisibilityOff />;
        }
    }

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}
                maxWidth="xl"
                PaperProps={{
                    sx: {
                       height: 900,
                        maxHeight: 900,
                    }
                }}>
                <DialogTitle>Détail de {item}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: '800px',
                            height: '650px',
                        }}>
                        <DirProperty item={detail} selectedLevel={itemId} />
                        <Box>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="SJs" {...a11yProps(0)} />
                                <Tab label="Employés" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <SjTable item={item} handleMigrate />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <EmployeeTable item={item} />
                        </TabPanel>
                    </div>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        onClick={() => handleHide(detail)}>
                        <Tooltip id="btn-hide" title={hideButtonLabel()}>
                            {HideButtonIcon()}
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={handleMigratePpopup}>
                        <Tooltip id="btn-move" title="Migrer vers...">
                            <DriveFileMove />
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={handleClose}>
                        <Tooltip id="btn-close" title="Fermer">
                            <Close />
                        </Tooltip>
                    </IconButton>
                    <Popover open={open}
                        onClose={() => setAncholELMigratePopup(null)}
                        anchorEl={ancholELMigratePopup}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <MigratePopup handleMigrate={(structure) => handleMigrate(detail, structure)} />
                    </Popover>
                </DialogActions>
            </Dialog>
        </>
    );
}