import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

const baseUrl = config.REACT_APP_BASE_API_URL
    + 'Structures';

export function GetStructures(endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetDistributions(criterias, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/Distributions";
    if (criterias != null) {
        if (criterias.isHidden != null && criterias.isHidden != undefined)
            currentUrl += "?isHidden=" + criterias.isHidden;
        if (criterias.showCountry != null && criterias.showCountry != undefined)
            currentUrl += "&showCountry=" + criterias.showCountry;
        if (criterias.showErt != null && criterias.showErt != undefined)
            currentUrl += "&showErt=" + criterias.showErt;
        if (criterias.freeSearch != null && criterias.freeSearch != undefined)
            currentUrl += "&freeSearch=" + criterias.freeSearch;
    }
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetDetailsByCountryAndBu(country, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/CountryDetail";
    var structures = CountryBuSplit(country);
    if (structures) {
        currentUrl += "?country=" + structures.country;
        currentUrl += "&bu=" + structures.bu;
    }
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetDetailsByDir(dir, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/DirDetail";
    currentUrl += "?dir=" + dir;
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetSjsByCountryAndBu(country, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/SjsByCountryAndBu";
    var structures = CountryBuSplit(country);
    if (structures) {
        currentUrl += "?country=" + structures.country;
        currentUrl += "&bu=" + structures.bu;
    }
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function GetDistributionsWithErt(endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/ErtDistributions";
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function Migrate(item, structure, type, successHandler, errorHandler) {

    var currentUrl = baseUrl + "/Migrate/" + type;

    var form_data = new FormData();

    form_data.append('SourceStructure.DirectionLevel2Id', item.directionLevel2Id);
    form_data.append('SourceStructure.DirectionLevel3Id', item.directionLevel3Id);
    form_data.append('SourceStructure.DirectionLevel4Id', item.directionLevel4Id);
    form_data.append('SourceStructure.DirectionLevel2Name', item.directionLevel2Name);
    form_data.append('SourceStructure.DirectionLevel3Name', item.directionLevel3Name);
    form_data.append('SourceStructure.DirectionLevel4Name', item.directionLevel4Name);
    form_data.append('SourceStructure.StcSjIdentifier', item.stcSjIdentifier);
    form_data.append('SourceStructure.StcCountryId', item.stcCountryId);

    
    form_data.append('TargetStructure.DirectionLevel2Id', emptyIfUndefined(structure.directionLevel2Id));
    form_data.append('TargetStructure.DirectionLevel3Id', emptyIfUndefined(structure.directionLevel3Id));
    form_data.append('TargetStructure.DirectionLevel4Id', emptyIfUndefined(structure.directionLevel4Id));
    form_data.append('TargetStructure.DirectionLevel2Name', emptyIfUndefined(structure.directionLevel2Name));
    form_data.append('TargetStructure.DirectionLevel3Name', emptyIfUndefined(structure.directionLevel3Name));
    form_data.append('TargetStructure.DirectionLevel4Name', emptyIfUndefined(structure.directionLevel4Name));

    var response = ApiActions.postMultipartAPI(currentUrl, form_data)
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}


export function MigrateSj(sj, structure, successHandler, errorHandler) {

    var currentUrl = baseUrl + "/MigrateCountry/" + sj;

    var form_data = new FormData();

    form_data.append('stcCountryId', structure.stcCountryId);
    form_data.append('stcCountryLabel', structure.stcCountryLabel);

    var response = ApiActions.postMultipartAPI(currentUrl, form_data)
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}

export function PostStructure(item, successHandler, errorHandler) {

    var response = ApiActions.postAPI(baseUrl, JSON.stringify(item))
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}
export function HideStructure(item, itemType, itemId, successHandler, errorHandler) {

    var currentUrl = baseUrl + "/Hide/" + itemType + '/' + itemId;
    var response = ApiActions.postAPI(currentUrl, JSON.stringify(item))
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}

function CountryBuSplit(countryAndBu) {
    var country = countryAndBu;
    var bu = null;
    if (countryAndBu.indexOf(' - ') > 0) {
        var split = countryAndBu.split(' - ');
        country = split[0].trim();
        bu = split[1].trim();
    }
    return { country: country, bu: bu };
}

function emptyIfUndefined(value) {
    if (value == undefined)
        return "";
    return value;
}