import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

const baseUrl = config.REACT_APP_BASE_API_URL
    + 'Employees';

export function GetEmployeesByCountryAndBu(country, endFunctionHandler, errorHandler) {

    var currentUrl = baseUrl + "/EmployeesByCountryAndBu";
    var structures = CountryBuSplit(country);
    if(structures){
        currentUrl += "?country=" + structures.country;
        currentUrl += "&bu=" + structures.bu;
    }
    ApiActions.getAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}


function CountryBuSplit(countryAndBu){
    var country = countryAndBu;
    var bu = null;
    if (countryAndBu.indexOf(' - ') > 0){
        var split = countryAndBu.split(' - ');
        country = split[0].trim();
        bu = split[1].trim();
    }
    return {country: country, bu: bu};
}