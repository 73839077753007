import './App.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Startup from 'pages/startup';
import AuthProvider from 'components/security/authProvider';
import PublicRoute from 'components/security/publicRoute';
import PrivateRoute from 'components/security/privateRoute';
import Dashboard from 'pages/home';
import { theme } from 'layouts/theme';


function App({ authentReducer }: any) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <PublicRoute path="/" component={Startup} />
        <PrivateRoute path="/Home" component={Dashboard} />
      </AuthProvider>
    </ThemeProvider>
  );
}


export default App;