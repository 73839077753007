
import React, { useEffect } from "react";
import { GetDistributions, PostStructure, HideStructure, Migrate } from "services/structureApiCaller";
import CircleLoader from "react-spinners/CircleLoader";
import { Tree } from 'react-tree-graph';
import useWindowDimensions from "services/windowDimensions";
import { AnimatedTree } from 'react-tree-graph';
import "./graph.css";
import { DialogCountry } from "./dialogCountry";
import ScrollBar from 'react-perfect-scrollbar';
import { SearchCriteria } from "components/graphView/searchCriteria";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { DialogDir } from "./dialogDir";
import { Slider } from "@mui/material";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function GraphComponent() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isFirsTime, setIsFirstTime] = React.useState(true);
    const [items, setItems] = React.useState([]);
    const [isDialogCountryOpen, setIsDialogCountryOpen] = React.useState(false);
    const [isDialogDirOpen, setIsDialogDirOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [selectedItemType, setSelectedItemType] = React.useState(null);
    const [selectedItemId, setSelectedItemId] = React.useState(null);
    const [searchCriteria, setSearchCriteria] = React.useState({
        isHidden: false,
        showCountry: true,
        showErt: false,
        freeSearch: null,
    });
    const [sliderValue, setSliderValue] = React.useState(50);


    var dim = useWindowDimensions();
    var graphwidth = 1400;
    var divHeight = 600;
    if (dim.height > 900) {
        graphwidth = 1800;
        divHeight = 700;
    }
    useEffect(() => {
        if (isFirsTime) {
            setIsFirstTime(false);
            loadStructures(searchCriteria);
        }
    }, []);

    function loadStructures() {
        setIsLoading(true);
        GetDistributions(
            searchCriteria,
            (data) => {
                setItems(data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    }

    function handleClick(event, node) {
        var structureType = detectStructureTypeAndExtractId(node);
        setSelectedItem(node);
        switch (structureType) {
            case 'Ert':
            case 'country':
                setIsDialogCountryOpen(true);
                break;
            case 'Activité':
            case 'Zone':
            case 'Bu':
                setIsDialogDirOpen(true);
                break;
            default:
                break;
        }
    }

    function detectStructureTypeAndExtractId(item) {
        var itemType = 'country';
        var itemId = 'na';
        if (item.indexOf('Ert') >= 0) {
            var start = item.indexOf('ERT');
            var end = item.length;
            itemId = item.substring(start, end);

            var start2 = item.indexOf(' ');
            itemType = 'Ert';
        }
        if (item.indexOf('(DIR') > 0) {
            var start = item.indexOf('(');
            var end = item.indexOf(')');
            itemId = item.substring(start + 1, end);

            var start2 = item.indexOf(' ');
            itemType = item.substring(0, start2);
        }
        setSelectedItemType(itemType);
        setSelectedItemId(itemId);
        return itemType;
    }

    function renderPopup() {
        switch (selectedItemType) {
            case 'country':
            case 'Ert':
                return (
                    <DialogCountry
                        isOpen={isDialogCountryOpen}
                        item={selectedItem}
                        itemId={selectedItemId}
                        handleHide={(detail) => handleHide(detail)}
                        handleMigrate={(item, structure) => handleMigrate(item, structure)}
                        handleClose={() => setIsDialogCountryOpen(false)} />
                );
            case 'Activité':
            case 'Zone':
            case 'Bu':
                return (
                    <DialogDir
                        isOpen={isDialogDirOpen}
                        item={selectedItem}
                        itemId={selectedItemId}
                        handleHide={(detail) => handleHide(detail)}
                        handleMigrate={(item, structure) => handleMigrate(item, structure)}
                        handleClose={() => setIsDialogDirOpen(false)}
                    />
                );
            default:
                return null;
        }

    }

    function handleMigrate(item, structure) {
        setIsDialogCountryOpen(false);
        setIsDialogDirOpen(false);

        Migrate(item, structure, selectedItemType,
            () => loadStructures(),
            () => loadStructures());

    }

    function handleHide(detail) {
        setIsDialogCountryOpen(false);
        setIsDialogDirOpen(false);
        setIsLoading(true);
        HideStructure({ ...detail, isIgnored: !detail.isIgnored }
            , selectedItemType, selectedItemId,
            () => loadStructures(),
            () => loadStructures());
    }

    if (isLoading)
        return (
            <CircleLoader />
        );


    else if (items != null && items.length > 0)
        return (
            <>
                <SearchCriteria
                    criterias={searchCriteria}
                    handleSearch={() => loadStructures()}
                    handleSearchCriteriaChange={(newCriteria) => setSearchCriteria(newCriteria)} />
                <div
                    style={
                        {
                            backgroundColor: '#535755',
                            height: divHeight + 'px'
                        }
                    }>
                    <ScrollBar>
                        <div className="graphContainer"
                            style={
                                {
                                    backgroundColor: '#535755',
                                    height: divHeight + 'px'
                                }
                            }>
                            <AnimatedTree
                                data={items[0]}
                                height={sliderValue * 30}
                                width={graphwidth}
                                gProps={{
                                    onClick: handleClick,
                                }}
                            />
                            {renderPopup()}
                        </div>
                    </ScrollBar>
                    <Slider
                        size="small"
                        defaultValue={sliderValue}
                        onChange={(event, newValue) => {
                            setSliderValue(newValue);
                        }
                        }
                    />
                </div>
            </>
        );
    else
        return (
            <>
                <SearchCriteria
                    criterias={searchCriteria}
                    handleSearch={() => loadStructures()}
                    handleSearchCriteriaChange={(newCriteria) => setSearchCriteria(newCriteria)} />
                <>No Data</>
            </>
        );
}