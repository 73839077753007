import { CheckBox } from "@mui/icons-material";
import { FormControl, FormControlLabel, InputLabel, TextField } from "@mui/material";
import React from "react";

export function DirProperty({ item, selectedLevel, property, handleChange }) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [propertyValue, setPropertyValue] = React.useState("");
    const [displayZone, setDisplayZone] = React.useState("line");
    const [displayBu, setDisplayBu] = React.useState("line");
    const [currentItem, setCurrentItem] = React.useState({});

    React.useEffect(() => {
        setCurrentItem(item);
    }, [item, property]);

    function loadPropertyValue() {
        setIsLoading(true);
        if (item) {
            setIsLoading(false);
            // GetCountryPropertyValue(item, property, (data) => {
            //     setPropertyValue(data);
            //     setIsLoading(false);
            // });
        }
    }

    function detectItemLevel(){
        if(currentItem.directionLevel2Id == selectedLevel){
            return "Activity";
        }
        else if(currentItem.directionLevel3Id == selectedLevel){
            return "Zone";
        }
        else if(selectedLevel != 'na'){
            return "Bu";
        }
        else return "Country";
    }

    function DisplayValue(typeDir)
    {
        var currentDir = detectItemLevel();
        if(currentDir == "Activity")
        {
            if(typeDir == "Zone")
                return "none";
            else if(typeDir == "Bu")
                return "none";
        } 
        else if(currentDir == "Zone")
        {
            if(typeDir == "Bu")
                return "none";
        }
        if(typeDir == "Country")
        {
            if(typeDir != currentDir)
                return "none";
        }

        return "line";
    }

    function DisableActivity() {
        if (currentItem.directionLevel2Id == selectedLevel)
            return false;
        else
            return true;
    }

    function DisableZone() {
        if (currentItem.directionLevel3Id == selectedLevel)
            return false;
        else
            return true;
    }

    function DisableBu() {
        if (currentItem.directionLevel4Id == selectedLevel)
            return false;
        else
            return true;
    }

    function DisableCountry() {
        if (selectedLevel == 'na')
            return false;
        else
            return true;
    }

    

    if (isLoading || item == null || item == undefined)
        return <div>Chargement...</div>;
    else
        return (
            <div style={
                {
                    marginTop: '10px',
                    marginBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5',
                    padding: '5px',
                }
            }>
                <TextField
                    style={{
                        marginBottom: '10px',
                    }}
                    disabled={DisableActivity()}
                    label="Activity"
                    value={currentItem.directionLevel2Name || ''}
                />
                <TextField
                    disabled={DisableZone()}
                    style={{
                        marginBottom: '10px',
                        display: DisplayValue("Zone"),
                    }}
                    label="Zone"
                    value={currentItem.directionLevel3Name || ''}
                />
                <TextField
                    disabled={DisableBu()}
                    style={{
                        marginBottom: '10px',
                        display: DisplayValue("Bu"),
                    }}
                    label="Business Unit"
                    value={currentItem.directionLevel4Name || ''}
                />
                <TextField
                    disabled={DisableCountry()}
                    style={{
                        marginBottom: '10px',
                        display: DisplayValue("Country"),
                    }}
                    label="Pays"
                    value={currentItem.stcCountryLabel || ''}
                />
            </div>
        );
}