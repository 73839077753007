import React from "react";

export const employeeTableColumns = [
        {
          Header: 'Login',
          accessor: 'login', // accessor is the "key" in the data
          width: 200,
        },
        {
          Header: 'Sj',
          accessor: 'sJ_Id',
          width: 200,
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
          width: 200,
        },
        {
          Header: 'First Name',
          accessor: 'firstName',
          width: 200,
        },
    ];