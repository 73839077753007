import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { TabPanel } from "components/common/tabPanel";
import { GraphComponent } from "components/graphView/graphComponent";
import { GraphComponentWithErt } from "components/graphView/graphComponentWithErt";
import { TableComponent } from "components/tableView/tableComponent";
import MainLayout from "layouts/mainLayout";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Home({ }) {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <MainLayout>
        <GraphComponent />
      </MainLayout>
    </>
  );
}
