import { CallSplit, HideSource, Search } from "@mui/icons-material";
import { Button, IconButton, Popover } from "@mui/material";
import { Table } from "components/common/table";
import { MigrateSjPopup } from "components/graphView/migrateSjPopUp";
import React from "react";
import { GetSjsByCountryAndBu, MigrateSj } from "services/structureApiCaller";

export function SjTable({ item, handleMigrate }) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [sjs, setSjs] = React.useState([]);

    const anchorRef = React.useRef();
    const [ancholELMigratePopup, setAncholELMigratePopup] = React.useState(null);
    const [selectedButton, setSelectedButton] = React.useState(null);
    const open = Boolean(ancholELMigratePopup);

    const sjTableColumns = [
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div>
                        <IconButton id={"btn" + row.id} color="primary" size="small"
                            onClick={handleMigratePpopup}
                        >
                            <CallSplit fontSize="small" />
                        </IconButton>
                        <IconButton id={"btnHide" + row.id} color="primary" size="small"
                        >
                            <HideSource fontSize="small" />
                        </IconButton>
                        <Popover open={selectedButton == ("btn" + row.id) && open}
                            onClose={() => setAncholELMigratePopup(null)}
                            anchorEl={anchorRef}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}>
                            <MigrateSjPopup handleMigrate={(structure) => handleMigrate(row, structure)} />
                        </Popover>
                    </div>
                );
            }
        },
        {
            Header: 'Id',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: 'Nom',
            accessor: 'name',
            width: 300,
        },
        {
            Header: 'Code Pays',
            accessor: 'countryCode',
            width: 100,
        },
        {
            Header: "Effectif",
            accessor: 'nbEmployee',
            width: 100,
        }
    ];

    React.useEffect(() => {
        loadStructures();
    }, [item]);



    function handleMigratePpopup(e) {
        setAncholELMigratePopup(e.currentTarget);
        setSelectedButton(e.currentTarget.id);
    }

    function loadStructures() {
        setIsLoading(true);
        if (item) {
            setIsLoading(true);
            GetSjsByCountryAndBu(item, (data) => {
                setSjs(data);
                setIsLoading(false);
            });
        }
    }


    function handleMigrate(row, structure) {
        setIsLoading(true);
        MigrateSj(row.original.id, structure,
            () => {
                loadStructures();
            },
            () => {
                loadStructures();
            });
    }


    if (isLoading)
        return <div>Chargement...</div>;
    else
        return (
            <Table columns={sjTableColumns} data={sjs} ref={anchorRef} />
        );
}