import { Popover, TextField } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";

export function MigratePopup({ handleClose, handleMigrate }) {

    const [structure, setStructure] = React.useState({
        directionLevel2Id: "",
        directionLevel2Label: "",
        directionLevel3Id: "",
        directionLevel3Label: "",
        directionLevel4Id: "",
        directionLevel4Label: "",
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}>

            <div
                style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <TextField
                    required
                    style={{
                        marginRight: '5px',
                    }}
                    label="Nouvelle activité Id"
                    value={structure.directionLevel2Id}
                    onChange={(e) => setStructure({...structure, directionLevel2Id : e.target.value})}
                />
                <TextField
                    label="Nouvelle activité Label"
                    value={structure.directionLevel2Name}
                    onChange={(e) => setStructure({...structure, directionLevel2Name : e.target.value})}
                />
            </div>


            <div
                style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <TextField
                    style={{
                        marginRight: '5px',
                    }}
                    required
                    label="Nouvelle zone Id"
                    value={structure.directionLevel3Id}
                    onChange={(e) => setStructure({...structure, directionLevel3Id : e.target.value})}
                />
                <TextField
                    label="Nouvelle zone Label"
                    value={structure.directionLevel3Name}
                    onChange={(e) => setStructure({...structure, directionLevel3Name : e.target.value})}
                />
            </div>

            <div
                style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <TextField
                    style={{
                        marginRight: '5px',
                    }}
                    required
                    label="Nouveau BU Id"
                    value={structure.directionLevel4Id}
                    onChange={(e) => setStructure({...structure, directionLevel4Id : e.target.value})}
                />
                <TextField
                    label="Nouvelle Bu Label"
                    value={structure.directionLevel4Name}
                    onChange={(e) => setStructure({...structure, directionLevel4Name : e.target.value})}
                />
            </div>
            <Button onClick={() => handleMigrate(structure)}>Migrer vers une activité</Button>
        </div >
    );
}