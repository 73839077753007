import { Search } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

export function SearchCriteria({ criterias, handleSearchCriteriaChange, handleSearch }) {

    return (
        <div
            style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '15px'
                }
            }>
            <div
                style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                    }
                }
            >
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                        }
                    }>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={criterias.isHidden}
                                onChange={(e) => handleSearchCriteriaChange({ ...criterias, isHidden: e.target.checked })}
                            />}
                        label="Afficher les structures masquées"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={criterias.showErt}
                                onChange={(e) => handleSearchCriteriaChange({ ...criterias, showErt: e.target.checked })}
                            />}
                        label="Afficher les ERTs"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={criterias.showCountry}
                                onChange={(e) => handleSearchCriteriaChange({ ...criterias, showCountry: e.target.checked })}
                            />}
                        label="Afficher les Pays"
                    />
                </div>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                        }
                    }>
                    <FormControlLabel
                        control={<input
                            type="text"
                            style={{ marginLeft: '10px' }}
                            value={criterias.freeSearch}
                            onChange={(e) => handleSearchCriteriaChange({ ...criterias, freeSearch: e.target.value })}
                        />}
                        label="Filtrer sur un label de structure"
                        labelPlacement="start"
                        style={{ marginLeft: '0px' }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}>
                <Button
                    variant="contained"
                    endIcon={<Search />}
                    onClick={() => handleSearch()}
                >Rechercher</Button>
            </div>
        </div >
    );
}